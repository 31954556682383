<template lang='pug'>
  .home-features
    h2(id='features') SekuriTik Tercih Edenler
    // h3 Not all teams need the same set of features. That's why SekuriTik offers a wide range of modules that can be turned on/off on demand.
    v-tabs.mt-4(
      v-model='currentTab'
      :fixed-tabs='$vuetify.breakpoint.xlOnly'
      centered
      slider-color='primary'
      show-arrows
      next-icon=''
      prev-icon=''
      )
      v-tab(
        v-for='ft of features'
        :key='ft.key'
        :href='`#tab-` + ft.key'
        ) {{ft.title}}
    v-tabs-items(v-model='currentTab')
      v-tab-item(v-for='ft of features', :key='ft.key', :value='`tab-` + ft.key', :transition='false', :reverse-transition='false')
        v-container.mt-5(grid-list-xl)
          v-layout(row, wrap, justify-center)
            v-flex(xs12, sm6, v-for='(tl, idx) of ft.tiles', :key='ft.key+idx')
              .home-features-tile.animated.fadeInUp(:class='`wait-p` + idx + `s`')
                img(:src='tl.icon')
                h5(v-html='tl.title')
                span(v-html='tl.description')
</template>

<script>

export default {
  name: 'HomeFeatures',
  data () {
    // eslint-disable-next-line no-return-assign
    return {
      currentTab: 'tab-authentication',
      features: [
        {
          key: 'Editors',
          title: 'Perakende',
          tiles: [
            {
              icon: HTMLImageElement.height = 'https://res.cloudinary.com/sekuritim/image/upload/v1575911676/WifiFastSpot/logo_panco.png'
              // title: 'Panço'
              // description: 'The most popular format for documentation among developers. Includes live preview and toolbar / keyboard shortcuts quick access.'
            }
          ]
        },
        {
          key: 'History',
          title: 'Otel / Avm',
          tiles: [
            {
              icon: HTMLImageElement.height = ('https://res.cloudinary.com/sekuritim/image/upload/v1575912110/WifiFastSpot/logo.png')
              // title: 'Anitta Hotel',
              // description: 'All content modifications are tracked. You can revert to a previous state or recover a deleted page at any time. Easily keep track of who changed what.'
            }
          ]
        },
        {
          key: 'Localization',
          title: 'Restoran',
          tiles: [
            {
              icon: HTMLImageElement.height = ('https://res.cloudinary.com/sekuritim/image/upload/v1575924170/WifiFastSpot/pluskitchen.jpg')
              // title: 'Available in your language',
              // description: 'SekuriTik is translated in over 25 languages! If your language is not available yet, help us translating it with our easy to use tools. No coding required!'
            },
            {
              icon: HTMLImageElement.height = ('https://res.cloudinary.com/sekuritim/image/upload/q_100/v1576051435/WifiFastSpot/caytass.png')
              // title: 'Çaytaş',
              // description: 'Right-to-left languages are fully supported.'
            },
            {
              icon: HTMLImageElement.height = ('https://res.cloudinary.com/sekuritim/image/upload/c_scale,w_300/v1576051890/WifiFastSpot/cayla.png')
              // title: 'Çayla',
              // description: 'Make your wiki available in multiple languages. Quickly switch between languages for the same page.'
            }
          ]
        },
        {
          key: 'Media Assets',
          title: 'Sağlık',
          tiles: [
            {
              icon: HTMLImageElement.height = ('https://res.cloudinary.com/sekuritim/image/upload/c_scale,w_300/v1575971359/WifiFastSpot/HairExpertt.png')
              // title: 'Hair EXpert',
              // description: 'Upload and manage your media assets from the Assets Manager. Easily categorize your assets in folders and see where they are used.'
            }
          ]
        },
        {
          key: 'egitim',
          title: 'Eğitim',
          tiles: [
            {
              icon: HTMLImageElement.height = ('https://res.cloudinary.com/sekuritim/image/upload/v1575972287/WifiFastSpot/Esenyurt%C3%9Cni.png')
              // title: 'Esenyurt Üniversitesi',
              // description: 'Insert code snippets with full color syntax Highlighting and line numbering.'
            }
          ]
        },
        {
          key: 'Finance',
          title: 'Finans',
          tiles: [
            {
              icon: HTMLImageElement.height = ('https://res.cloudinary.com/sekuritim/image/upload/v1505842525/www/referanslar/tebyatirim.png')
              // title: 'TEB Yatırım',
              // description: 'Synchronize or backup your content to popular Git services such as GitHub, GitLab, BitBucket, Azure DevOps and more.'
            }
          ]
        }
      ]
    }
  }
}
</script>

<style lang='scss'>
.home-features {
  background-color: var(--v-greyish-lighten4);
  text-align: center;
  padding: 100px 0 50px 0;
  color: var(--v-greyish-darken2);

  @media screen and (max-width: 599px) {
    padding: 50px 0 0 0;
  }

  .container {
    max-width: 1200px;
  }

  h2 {
    font-size: 30px;
    font-weight: 600;
    padding: 0 15px;
    line-height: 30px;

    @media screen and (max-width: 599px) {
      font-size: 25px;
    }
  }
  h3 {
    font-size: 16px;
    font-weight: 300;
    padding: 15px;
    color: var(--v-greyish-darken1);
  }

  .v-tabs__bar {
    background: var(--v-greyish-lighten4); // linear-gradient(to bottom, var(--v-greyish-lighten4) 0%, #FFF 100%);
    box-shadow: inset 0px -2px rgba(#6754e2, .1);

    .v-tabs__item {
      color: var(--v-greyish-darken2);
      &.v-tabs__item--active {
        color: var(--v-primary-base);
      }
    }

    .v-tabs__icon--next {
      background: transparent url(../assets/icons/metro-forward.svg) no-repeat center right;
    }
    .v-tabs__icon--prev {
      background: transparent url(../assets/icons/metro-back.svg) no-repeat center left;
    }
  }

  &-tile {
    text-align: center;
    padding-bottom: 50px;

    h5 {
      font-weight: 600;
      font-size: 17px;
      margin: 5px 0 10px 0;
    }

    span {
      font-size: 15px;
      font-weight: 300;
      color: var(--v-greyish-darken1);
    }
  }
}
</style>
